import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/templates/article-page-layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Intro = makeShortcode("Intro");
const Body = makeShortcode("Body");
const Outro = makeShortcode("Outro");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Intro mdxType="Intro">
Echoplex Techno Masterclassを何度も見ている
      <p>{`Echoplexという人のテクノミュージック作曲講座みたいのを見たんだけれども、これがすごい良くて何回も移動中に聞いている。音楽の話というよりか、何かを作るということについてとても納得することを言っていたので。`}</p>
    </Intro>
    <Body mdxType="Body">
      <h2 {...{
        "id": "何それ"
      }}>{`何それ`}</h2>
      <p>{`紹介の動画は以下。`}</p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.adsrsounds.com/product/courses/echoplex-techno-masterclass/",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`ADSR: Echoplex Techno Masterclass`}</a></li>
      </ul>
      <p>{`以下で買えます💰`}</p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.youtube.com/watch?v=T9WRvWzrlEY",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`Echoplex Techno Masterclass - 3+ Hour Techno Production Masterclass`}</a></li>
      </ul>
      <p>{`ただ英語だし字幕もないけど。`}</p>
      <p>{`自分はEchoplexという人自体知らなかった。Youtubeでテクノを作る方法を解説してる動画とかあって、見てたら、あーこういうの結構面白いなと思ったので購入。ちなみに自分はもう15年ぐらいやって無いけど、そういう打ち込みの音楽をやってました。一人で黙々と。`}</p>
      <h2 {...{
        "id": "曲が作れない"
      }}>{`曲が作れない`}</h2>
      <p>{`まず音楽についてなのだけれども、最近自分はそういう動画を見て、またやってみようかなーと思ったんですよ。音楽を作るということで言うと、`}<a parentName="p" {...{
          "href": "https://www.ableton.com/en/",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Ableton Live`}</a>{`というソフトは自分は大昔に買ってまして、あらいがたいことにそんな昔のバージョンでもアップグレード版で安く最新のものが使えるとのことで。ついでに`}<a parentName="p" {...{
          "href": "https://www.ableton.com/en/push/",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Push`}</a>{`という機材まで買って、やる気満々だなという。`}</p>
      <p>{`で、そういうソフトの使い方は覚えているもので、普通にソフトの操作方法とかは問題なく、まぁスキーは子供の頃やったことがある〜みたいな人が大人になって言ってもまた滑れるみたいな。あとはこういう曲を作る場合にはサンプリングCDなどという、何か短いループを好きに使っていいよみたいなのがあるが、それも太古のHDDに眠っていたので環境は万全と言える。`}</p>
      <p>{`で、じゃあ曲を作ってみようかとなるんだけど、これができないんだなーと。どうできないかって言うと、具体的には、色々ループを並べたり加工したり、今では何かそういう細かいパラメータをいじったりするのものですね、iPadのソフトなんかでとても直感的にできるのですよ。長く音楽をやっていたので、フツーに知識もある。でも、できない。ずっとソフトとにらめっこして、何日も過ぎているのに何一つ完成していない……ってなるんです。`}</p>
      <p>{`これは別に今だからっていうのでは無くて。高校のころから音楽はやってたんだけれども、その時から自分にとって曲を作るという行為において、そうなることはごく普通にあることであった。今はその頃と比較すると、スキルがあるものの、なおできないのだから、何でなんだろうってなるか？ いや、なんでとかも思わない。ただできない時間が続くという感じなのだ。`}</p>
      <h2 {...{
        "id": "原稿が書けないwebサイトが作れない"
      }}>{`原稿が書けない／Webサイトが作れない`}</h2>
      <p>{`で、同じようなことっていうのは、似たことで色々ある。これは、そのEchoplex氏の動画を見た後に改めて思ったことなのだけれど。`}</p>
      <p>{`たとえば原稿。自分は弊社、`}<a parentName="p" {...{
          "href": "https://www.pxgrid.com/",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`PixelGrid`}</a>{`の仕事の一環で`}<a parentName="p" {...{
          "href": "https://www.codegrid.net/about/",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`CodeGrid`}</a>{`という媒体で記事を書くことが仕事の一部なので、そういう時も、いや全然原稿が書けないねっていうことはある。これは弊社メンバーの大半で共有できる感覚であると思うのだけれど。`}</p>
      <p>{`あと今年は`}<a parentName="p" {...{
          "href": "https://amzn.to/3s3TTOa",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`本`}</a>{`を書きましたが、これも書けてなかった期間は相当長い。いやこれはただ書いてなかっただけか……？ とも思うけど、いざ書くぞってなっても、書き出すところまでが時間がかかるのですよな。`}</p>
      <p>{`ほか、どうだろう。たとえば自分のWebサイトを作るとかいうのはどうです？ どういう風に作ろう、どうしたらいいだろうって悩みませんか？ 結果その日は何も進んで無いとか、あると思います。仕事で何か作る場合はね、こう目的がはっきりしてたりするからまた違うと思うんだけれど、自分のサイトをつくるってなると作れないってのはありえるかな〜と。まぁこれは自分はあるんですよね。あーだこーだいじっていじって何もできてないみたいな。`}</p>
      <h2 {...{
        "id": "echoplex氏曰く"
      }}>{`Echoplex氏曰く`}</h2>
      <p>{`そーいうのの答えというか、なんでそうなのかっていうのを、このEchoplexさんは話しているんですよ、この動画の中で。もう10回ぐらい聞いている。そしてその言葉を噛み締めている。`}</p>
      <p>{`Echoplex氏は動画の中でこんなことを言ってる。`}</p>
      <ul>
        <li parentName="ul">{`別に多くの人にとっては曲を作る必要などない`}</li>
        <li parentName="ul">{`完成させる必要性がそもそもない`}</li>
        <li parentName="ul">{`なぜ完成できないか？`}</li>
        <li parentName="ul">{`音楽を楽しんでしまうから？ 別にそれは問題ない。それを死ぬまで続けても何も問題無い`}</li>
        <li parentName="ul">{`自分にとっては「なぜそれが自分にとって重要か」を考えることが意味のあることだった`}</li>
        <li parentName="ul">{`時間は有限である。そこでなぜトラックを作るのか。`}</li>
        <li parentName="ul">{`今日のランチは中華を食べた？ じゃあ明日も中華を食べる？ その次も？ そういうことは無いでしょう。気持ちはその時で変わってしまう。`}</li>
        <li parentName="ul">{`Facebookでの評価が気になる？ ダレソレのチャートにランクインしてたのを見るとする。それでもう気持ちが変わってしまう。`}</li>
        <li parentName="ul">{`Ableton Liveをマスターするのに1,000時間かかるとする。1,000時間かけたら曲が作れる？ そういうわけでもない`}</li>
        <li parentName="ul">{`曲を作るというのは、その時の気持ちのキャプチャである`}</li>
        <li parentName="ul">{`曲を作るのはcraving（欲する気持ち）と似ている`}</li>
        <li parentName="ul">{`だから、曲を作れないというのはスキルの問題じゃない。曲を完成させようとするメンタルの能力の話である`}</li>
        <li parentName="ul">{`曲にはテーマがある。これがないと作れない。たとえばJeff Millsだったら…… タッタラッタ×4（`}<a parentName="li" {...{
            "href": "https://youtu.be/DwpedKWwS3w",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`♪Bells`}</a>{`）ってメロディがあって、そこからは外れなかったりする`}</li>
        <li parentName="ul">{`そんな風に曲を作る時は自分はテーマを決める。もともと何が良いと思ったのか。それはアイデアだ。`}</li>
        <li parentName="ul">{`そこからずれて来たらすべてやり直して最初に戻る。そうすると、あぁこれが自分のアイデアだったと立ち戻れる`}</li>
        <li parentName="ul">{`Ableton Liveみたいな便利なソフトがあり、それは使えば自由に編集できることができる。でも、それによりますます曲を作るということが難しくなる。`}</li>
      </ul>
      <p>{`……とそんな感じで、Echoplex氏は一つのアイデアから曲を作りきるまでをデモするってやつがこの動画。`}</p>
      <p>{`こんなことを他にも色々言っていて、上記はごく一部なのだけれど、これを聞いて、いやはやなんか色々と納得してしまったんですわ。`}</p>
      <h2 {...{
        "id": "テーマがなければ完成はしない"
      }}>{`テーマがなければ完成はしない`}</h2>
      <p>{`まずテーマが無いと完成しないっていうところね。これ、音楽については、たとえばサンプリングCDというものよ。色々好きなループ、民族音楽みたいなパーカッションだとか、かっちょいいピアノのリフとか、ロイヤリティーフリーで使えるわけなんですけども。なんというかね、それを組み合わせてなにかするのが音楽？それって意味あるか？とかいうのも思ったりはしますね。特に打ち込み音楽だと。`}</p>
      <p>{`たとえば「HOGE SOUNDのハウスセット」みたいので、できてるループ…… ピアノとかパーカッションとかベースとか色々が分解されてるみたいのが詰め合わされて売ってるのがよくあるわけだけど、それを組み合わせて作ったそのトラックというのはあなたにとっての何？ってなるよねーと。で、あ、これはやはりそうだよな。それを組み合わせるだけでは曲を作る意味は皆無だよなと、改めて思ったのだった。`}</p>
      <p>{`だから、曲を作るということは、自分だったらどう作るってのが重要っていうことを言ってるんだなーこのEchoplex氏は。いやはやいい事を言ってるんですよほんとに……`}</p>
      <h2 {...{
        "id": "原稿とかwebサイトとか"
      }}>{`原稿とかWebサイトとか`}</h2>
      <p>{`そんで、原稿書いたりとかWebサイト作ったりとかいう時ね、これもテーマがないと終わらんはまさにそうじゃない？ って思うんですよね。`}</p>
      <p>{`「〜〜について原稿を書く」という場合。まず、なんでそれを書きたいかとか、どういうことを伝えたいかみたいのがさ、無いんですよね、右往左往してる時。これほとんど今自分に言い聞かせてるみたいなものなんですが。無いんですよ。無いから、完成しないんですよっていうね。`}</p>
      <p>{`そしてだよ、「締切がなければ原稿は完成しない」のも全くこれね。そして悲劇は、テーマがしっくり来てないのに書いている原稿というものは、「締切を守ること」がテーマになってしまうんだよねーー！みたいなね。これだなーっていう。だから、原稿を終わらせるコツは、初めに「なんでこの原稿を書くのか」「この原稿は自分にとってなんの意味があるのか」を考えることなのかもしれない。もしかしたら。`}</p>
      <p>{`自分のWebサイトみたいなのもそうね。たとえば「自分のWebサイトを作ろうと思うんだけどなかなか完成しないだよね」みたいなのを誰かが言っていたとする。そうしたら、「あ？そう？それってなんのために作ってるの？」って思わないだろうか。それって冷たく聞こえる？ でも、考えてみたらまさにソコ、「なぜ作るのか？」だよね。`}</p>
      <p>{`これが目的のあるものだったら、そこはスキルで解決するんだけど、そうじゃないものは「なんで作るか？」「自分にとってどういう意味があるか？」これがないと完成しないっていうのは、そうだよな〜〜などなど思ったのだった。`}</p>
    </Body>
    <Outro mdxType="Outro">
      <p>{`そんなこんなでこの動画は繰り返し聞くたびに発見があるので、移動中に何度も聞いている。`}</p>
      <p>{`ちなみにこのWebサイトは、このようにTakazudoが自分で考えたことをアウトプットするというのがfirst priorityであり、あとはGatsbyを使いたかったことがモチベーションになって完成したものである。`}</p>
    </Outro>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      